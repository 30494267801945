import React from 'react';
import AutolokaImage from '../assets/autoloka.svg';
const Nav = () => {

  const [dropdown,setDropdown] = React.useState(false);

  return (
    <nav className="bg-primary">
      <div className="container mx-auto lg:px-16 md:px-8 px-4 flex items-center justify-between py-5 md:py-3">
        <img src={AutolokaImage} className="h-6" alt="" />
        <div className="items-center md:flex hidden">
          <button className="bg-white rounded-md text-primary mr-3 px-5 py-1">
            Login
          </button>
          <button className="bg-primary rounded-md text-white border-2 border-white px-5 py-1">
            Register
          </button>
        </div>
        <button onClick={()=> setDropdown(prev => !prev)} className="flex flex-col md:hidden">
          <div className="w-8 h-1 bg-white rounded"></div>
          <div className="w-8 h-1 bg-white rounded my-1"></div>
          <div className="w-8 h-1 bg-white rounded"></div>
        </button>
      </div> 
      {dropdown && <div className="flex md:hidden flex-col w-full p-5">
        <button className="bg-white rounded-md text-primary mb-4 px-5 py-1">
          Login
        </button>
        <button className="bg-primary rounded-md text-white border-2 border-white px-5 py-1">
          Register
        </button>
      </div>}
    </nav>
  )
}

export default Nav;