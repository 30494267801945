import Slider from "react-slick";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Jumbo1 from '../assets/jumbo-1.png';
import Jumbo1_2 from '../assets/jumbo-1-2.png';
import Jumbo1_3 from '../assets/jumbo-1-3.png';
import Jumbo1_4 from '../assets/jumbo-1-4.png';
import Jumbo2 from '../assets/jumbo-2.png';
import BenefitsImage1 from '../assets/benefits-1.png';
import Image1 from '../assets/image-1.png';
import BenefitsImage2 from '../assets/benefits-2.png';
import BenefitsImage3 from '../assets/benefits-3.png';
import BenefitsImage4 from '../assets/benefits-4.png';
import BenefitsImage5 from '../assets/benefits-5.png';
import InteriorImage from '../assets/interior.png';
import ExteriorImage from '../assets/exterior.png';
import BrandImage1 from '../assets/brand-1.png';
import BrandImage2 from '../assets/brand-2.png';
import BrandImage3 from '../assets/brand-3.png';
import BrandImage4 from '../assets/brand-4.png';
import BrandImage5 from '../assets/brand-5.png';
import BrandImage6 from '../assets/brand-6.png';
import BrandImage7 from '../assets/brand-7.png';
import BrandImage8 from '../assets/brand-8.png';
import BrandImage9 from '../assets/brand-9.png';
import ManImage from '../assets/man.png';

import Nav from '../components/nav';
import Footer from '../components/footer';

const benefits = [
  {
    image: BenefitsImage1,
    text: 'Above 8000 Products type'
  },
  {
    image: BenefitsImage2,
    text: 'Almost of all Car’s Brand'
  },
  {
    image: BenefitsImage3,
    text: 'Shipping Worldwide'
  },
  {
    image: BenefitsImage4,
    text: 'High quality products'
  },
  {
    image: BenefitsImage5,
    text: 'Affordable price'
  },
]

const brands = [BrandImage1,BrandImage2,BrandImage3,BrandImage4,BrandImage5,BrandImage6,BrandImage7,BrandImage8,BrandImage9]

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 5000,
};


function Index() {

  return (
    <Controller>
      <div className="bg-[#F9F9F9]">
      <Nav />
      <section className="relative w-full overflow-hidden">
          <Scene 
            duration="1500"
            pin={{ pushFollowers: true }}
            triggerHook={0}
            offset={0} >
            {(progress) => (
              <Timeline progress={progress} paused target={<div>
                <Slider {...settings} className="h-full overflow-x-hidden" >
                  <div className="">
                    <img src={Jumbo1} className="h-70vh object-cover" alt="jumbo" />
                  </div>
                  <div className="">
                    <img src={Jumbo1_2} className="h-70vh object-cover" alt="jumbo" />
                  </div>
                  <div className="">
                    <img src={Jumbo1_3} className="h-70vh object-cover" alt="jumbo" />
                  </div>
                  <div className="">
                    <img src={Jumbo1_4} className="h-70vh object-cover" alt="jumbo" />
                  </div>
                </Slider>
              </div>}>
                <Tween from={{scale: 1,opacity: 1}} to={{scale: 1.3,opacity: 0}} />
              </Timeline>
            )}
          </Scene>
          <Scene  
            duration="2000"
            pin={{ pushFollowers: true }}
            triggerHook={0}
            offset={0}>
            {(progress) => (
              <Timeline progress={progress} paused target={<div className="h-full w-full mx-auto lg:px-16 md:px-8 px-4 absolute top-0 left-0">
                <div className="w-full h-full flex flex-col items-center justify-center ">
                  <h1 className="text-white text-xl md:text-3xl font-oxanium mb-3">AUTOLOKA</h1>
                  <h2 className="text-white text-2xl md:text-4xl lg:text-5xl font-semibold text-center font-oxanium">The First Auto Accessories  <br /> Marketplace B2B Indonesia</h2>
                  <p className="text-white text-2xl my-9">All yo u need is here just explore us.</p>
                  <button className="bg-primary rounded text-white px-5 py-2">
                    Explore Now
                  </button>
                </div>
              </div>}>
              <Tween from={{y: 0}} to={{y: 100}} />
              </Timeline>
            )}
          </Scene>
        </section>

      <section>
        <div className="container mx-auto lg:px-16 md:px-8 px-4 flex items-center my-10 flex-col md:flex-row">
          <div className="flex-1">
            <h2 className="text-primary font-bold text-3xl font-oxanium">AUTOLOKA is B2B Leading</h2>
            <p className="text-black font-bold text-2xl mb-4 font-oxanium">Auto Accessories Marketplace</p>
            <p className="font-oxanium">We offer an array of automotive accessories solutions to support your business and help increase your store profit.</p>
          </div>
          <div className="flex-1 flex  items-center justify-center mt-10 md:mt-0">
            <img src={Image1} className="h-96" alt="" />
          </div>
        </div>
      </section>
      <section>
        <div className="container mx-auto lg:px-16 md:px-8 px-4 flex flex-col my-10">
          <h2 className="text-black text-xl font-semibold mb-8 font-oxanium">The Benefits that you can find here</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {benefits.map((el,i) => (
              <div key={i} className="group flex flex-col items-center justify-start p-6 bg-white shadow-my-shadow-2 rounded-md">
                <img src={el.image} className="group-hover:scale-125 transition duration-100" alt="" />
                <p className="font-semibold font-oxanium text-center group-hover:scale-75 transition duration-100">{el.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="relative">
          <div className="GRADIENT w-full h-full z-10 absolute top-0 left-0" />
          <img src={Jumbo2} className="w-full h-full object-cover absolute top-0 left-0 z-0" alt="" />
          <div className="mt-20 GRADIENT">
          <div className="container relative h-full mx-auto lg:px-16 md:px-8 px-4 py-16 z-20 flex justify-center flex-col ">
              <h2 className="font-oxanium text-4xl font-bold mb-10 text-white">Integrated to <br className="hidden md:block" />manufactures </h2>
              <p className="font-oxanium text-2xl text-white">Dont worry about pricing. We provide <br className="hidden md:block" /> affordable price from many factories. <br className="hidden md:block"    /> So, you can gain your maximum profit</p>
          </div>
          </div>
      </section>
      <section className="relative">
        <div className="container mx-auto lg:px-16 md:px-8 px-4 flex flex-col my-14">
          <p className="text-black font-bold text-2xl mb-10 font-oxanium">Products that you can find in <span className="text-primary text-3xl">AUTOLOKA</span></p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <img src={ExteriorImage} className="flex-1" alt="" />
            <img src={InteriorImage} className="flex-1" alt="" />
          </div>
        </div>
      </section>
      <section className="relative bg-[#E5ECF0] py-14">
        <div className="container mx-auto lg:px-16 md:px-8 px-4 flex flex-col">
          <p className="font-oxanium text-black font-bold text-2xl mb-10">For all car’s brand and types</p>
          <div className="w-full items-start flex">
            <div className="flex-3 mr-5">
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-2">
                {brands.map((el,i)=> (
                  <div key={i} className="shadow-md bg-white rounded-md flex items-center justify-center p-3">
                    <img src={el} className="" alt="" />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1 md:self-center flex items-start md:items-center justify-start ">
              <p className="text-gray-500 font-semibold">And many more</p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative ">
        <div className="container mx-auto lg:px-16 md:px-8 px-4 pt-10 flex flex-col-reverse md:flex-row">
            <div className="flex-2 mt-10 md:mt-0">
              <img src={ManImage} className="h-72 md:h-96 object-cover" alt="" />
            </div>
            <div className="flex-1 flex flex-col justify-center">
              <h2 className="text-primary font-bold text-4xl font-oxanium">JOIN NOW</h2>
              <p className="text-gray-600 my-5 font-medium font-oxanium">Find a thousand products to create the <br /> opportunities and increase your profit </p>
              <button className="bg-primary rounded text-white px-5 py-2 self-start">
                Sign Up
              </button>
            </div>
        </div>
      </section>
      <Footer />
    </div>
    </Controller>
  );
}

export default Index;
