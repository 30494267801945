import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Index from './pages/index';
import Tnc from './pages/tnc';
import Pp from './pages/pp';

const App =() => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/terms-and-condition" element={<Tnc />} />
          <Route path="/privacy-policy" element={<Pp />} />
        </Routes>
      </Router>
  );
}

export default App;