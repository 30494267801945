
import Playstore from '../assets/play-store.png';
import Appstore from '../assets/app-store.png';
import AutolokaImage from '../assets/autoloka.svg';

const Footer = () => {

    return (
        <section className="relative bg-primary py-14">
        <div className="container mx-auto lg:px-16 md:px-8 px-4 flex flex-col lg:flex-row">
            <div className="flex-3 flex justify-between flex-col md:flex-row">
                <div className="flex flex-col items-start flex-1 mr-7">
                  <img src={AutolokaImage} className="h-6 mb-6" alt="" />
                  <p className="text-white">Is the Number one B2B Marketplace for Auto Accessories in Indonesia.</p>
                </div>
                <div className="flex flex-col flex-1 mr-7 mt-5 md:mt-0">
                  <p className="text-white font-semibold text-xl font-oxanium">
                    PT Sehati Prima Makmur
                  </p>
                  <p className="text-white mt-5 mb-2">Address</p>
                  <p className="text-white">Jl. Tj. Pasir, Pangkalan, Kec. Teluknaga, Kabupaten Tangerang, Banten 15510 <br />
    Phone: +621 29128119
                  </p>
                </div>
                <div className="flex flex-col flex-1 lg:border-r border-white mr-4 mt-5 md:mt-0">
                    <a href="/terms-and-condition" className="text-white underline">
                        Terms of conditions
                    </a>
                  <a href="/privacy-policy" className="text-white underline">
                    Privacy Policy
                  </a>
                </div>
            </div>
            <div className="flex-1 flex flex-col mt-10 md:mt-0">
                <p className="text-white">Available on</p>
                <div className="flex items-center">
                  <img src={Playstore} className="mr-3" alt="" />
                  <img src={Appstore} alt="" />
                </div>
            </div>
        </div>
        <p className="text-white text-center mt-20">Powered by Crealoka © 2022 Autoloka All rights reserved. </p>
      </section>
    )
}

export default Footer